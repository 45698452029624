<template>
  <div class="updateFile">
    <div class="box" @click="!disable? onClick(): () => {}">
      <input ref="file"
        type="file"
        class="file-input"
        accept="image/*"
        capture="camera"
        @change="change" />
      <div class="file-image" v-if="file" :style="`background-image: url(${file.base64})`" />
      <div>
        <slot />
      </div>
    </div>
    <mt-progress :value="progress" :bar-height="3" />
  </div>
</template>

<script>
  import { oneImage } from '../utils/api'
  import lrz from 'lrz'
  export default {
    name: 'UpdateFile',
    props: {
      type: String,
      disable: {
        type: Boolean,
        default: false
      },
    },
    data() {
      return {
        loading: false,
        progress: 0,
        file: null
      };
    },
    mounted() {
      
    },
    methods: {
      onClick() {
        this.$refs.file.dispatchEvent(new MouseEvent('click')) 
      },
      async change(e) {
        if (this.loading) return
        this.loading = true
        let files = e.target.files;
        for (let i = 0; i < files.length; i++) {
          await lrz(files[i], {
            width: 1920,
            height: 1080,
            // quality: 0.8 // 自定义使用压缩方式
          })  
            .then(async (rst) => {
              // 成功时执行
              this.file = rst
              const formData = new FormData()
              formData.append('imageFront', rst.file)
              formData.append('type', this.type)
              formData.append('deviceId', 'H5')
              this.progress = 0
              await this.$axios({
                url: oneImage,
                method: 'post',
                data: formData,
                params: {
                  deviceId: 'H5',
                  type: this.type
                },
                timeout: 120 * 1000,
                onUploadProgress: ({ loaded, total }) => {
                  this.progress = (loaded / total) * 100 * 0.9
                }
              }).then((e) => {
                if (e.status.code == "000") {
                  this.progress = 100
                  this.$emit('change', e.body.imageId, rst);
                } else {
                  this.progress = 0
                  this.$toast(e.status.msg);
                }
              }).catch(() => {
                this.progress = 0
              })
            }).catch((err) => {
              // 失败时执行
              console.log(err)
            })
        }
        this.loading = false
      },
    }
  };
</script>

<style lang="scss" scoped>
.updateFile {
  .box {
    position: relative;
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    border-radius: 15px;
  }
  .file-input {
    display: none;
  }
  .file-image {
    border-radius: 15px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.6);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    img {
      height: 100%;
    }
  }
  .mt-progress {
    height: 3px;
    opacity: 0.5;
    margin-bottom: 5px;
  }
}
</style>