<template>
  <div class="uploadImage">
    <!-- 身份证 -->
    <div @click="popupIdcardFrontVisible = true">
      <update-file
        ref="idcardFront"
        type="IDCARD_FRONT"
        disable
        @change="(id, file) => {
          form.identifyCard = id;
          this.BEHAVIOR_ADD({
            id: 'P05_C01_B_IDENTIFYCARD',
            newValue: id
          })
          onUploadImg('P05_C01_E_EXIF', file)
        }"
      >
        <mt-cell :title="$t('identifyCard')">
          <i class="iconfont iconchenggong" :class="{ 'success': form.identifyCard }"></i>
        </mt-cell>
      </update-file>
    </div>
    <!-- 手持身份证 -->
    <div @click="popupHoldTheIdentifyCardVisible = true">
      <update-file
        ref="holdTheIdentifyCard"
        type="HANDHELD_IDCARD"
        disable
        @change="(id, file) => {
          form.holdTheIdentifyCard = id;
          this.BEHAVIOR_ADD({
            id: 'P05_C02_B_HOLDRHEIDENTIFYCARD',
            newValue: id
          })
          onUploadImg('P05_C02_E_EXIF', file)
        }"
      >
        <mt-cell>
          <div slot="title">
            <span v-if="brand.handheldUploadFlag == 'Y'" style="color: red;">*</span>
            {{ $t('holdTheIdentifyCard') }}
          </div>
          <i class="iconfont iconchenggong" :class="{ 'success': form.holdTheIdentifyCard }"></i>
        </mt-cell>
      </update-file>
    </div>
    <!-- 员工身份证 -->
    <div @click="popupEmployeeIdCardVisible = true">
      <update-file
        ref="employeeIdCard"
        type="WORK_CARD"
        disable
        @change="(id, file) => {
          form.employeeIdCard = id;
          this.BEHAVIOR_ADD({
            id: 'P05_C03_B_EMPLOYEEIDCARD',
            newValue: id
          })
          onUploadImg('P05_C03_E_EXIF', file)
        }"
      >
        <mt-cell :title="$t('employeeIdCard')">
          <i class="iconfont iconchenggong" :class="{ 'success': form.employeeIdCard }"></i>
        </mt-cell>
      </update-file>
    </div>
    <!-- 选民卡 -->
    <update-file
      type="VOTER_FRONT"
      @change="(id, file) => {
        form.voterCard = id;
        this.BEHAVIOR_ADD({
          id: 'P05_C04_B_VOTERCARD',
          newValue: id
        })
        onUploadImg('P05_C04_E_EXIF', file)
      }"
    >
      <mt-cell :title="$t('voterCard')">
        <i class="iconfont iconchenggong" :class="{ 'success': form.voterCard }"></i>
      </mt-cell>
    </update-file>
    <!-- 驾照 -->
    <update-file
      type="DRIVE_FRONT"
      @change="(id, file) => {
        form.driverLicense = id;
        this.BEHAVIOR_ADD({
          id: 'P05_C05_B_DRIVERLICENSE',
          newValue: id
        })
        onUploadImg('P05_C05_E_EXIF', file)
      }"
    >
      <mt-cell :title="$t('driverLicense')">
        <i class="iconfont iconchenggong" :class="{ 'success': form.driverLicense }"></i>
      </mt-cell>
    </update-file>
    <!-- 护照 -->
    <update-file
      type="PASSPORT"
      @change="(id, file) => {
        form.passport = id;
        this.BEHAVIOR_ADD({
          id: 'P05_C06_B_PASSPORT',
          newValue: id
        })
        onUploadImg('P05_C06_E_EXIF', file)
      }"
    >
      <mt-cell :title="$t('passport')">
        <i class="iconfont iconchenggong" :class="{ 'success': form.passport }"></i>
      </mt-cell>
    </update-file>
    <!-- 税卡 -->
    <update-file
      type="PAN_CARD"
      @change="(id, file) => {
        form.taxCard = id;
        this.BEHAVIOR_ADD({
          id: 'P05_C07_B_RAXCARD',
          newValue: id
        })
        onUploadImg('P05_C07_E_EXIF', file)
      }"
    >
      <mt-cell :title="$t('taxCard')">
        <i class="iconfont iconchenggong" :class="{ 'success': form.taxCard }"></i>
      </mt-cell>
    </update-file>

    <div class="border">
      <span class="title">{{$t('idcardExample')}}</span>
      <div class="content">
        <div v-for="index in 4" :key="index" class="item" @click="setDemoPic(index)">
          <img :src="require(`../../assets/uploaddemo/uploaddemo-${index}-b.png`)">
          <div class="txt">{{$t(`idcardExampleList[${index - 1}]`)}}</div>
        </div>
      </div>
    </div>
    <mt-popup v-if="popupVisible" v-model="popupVisible" popup-transition="popup-fade">
      <div class="demopic">
        <mt-swipe :auto="0" :defaultIndex="defaultIndex">
          <mt-swipe-item v-for="index in 4" :key="index">
            <img :src="require(`../../assets/uploaddemo/uploaddemo-${index}-a.png`)">
            <div class="txt">{{$t(`idcardExampleList[${index - 1}]`)}}</div>
          </mt-swipe-item>
        </mt-swipe>
        <div class="ok border-t" @click="popupVisible = false">{{$t('confirm')}}</div>
      </div>
    </mt-popup>
    <mt-popup v-model="popupIdcardFrontVisible">
      <div class="box">
        <div class="val">
          <img class="identifyCard" src="../../assets/identifyCard.png">
        </div>
        <div class="tips">
          {{ $t('identifyCardTips') }}
        </div>
        <div class="button">
          <mt-button
            type="primary"
            size="large"
            @click="popupIdcardFrontVisible = false; $refs.idcardFront.onClick()"
          >{{
            $t("confirm")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
    <mt-popup v-model="popupHoldTheIdentifyCardVisible">
      <div class="box">
        <div class="val">
          <img class="holdTheIdentifyCard" src="../../assets/holdTheIdentifyCard.png">
        </div>
        <div class="tips">
          {{ $t('holdTheIdentifyCardTips') }}
        </div>
        <div class="button">
          <mt-button
            type="primary"
            size="large"
            @click="popupHoldTheIdentifyCardVisible = false; $refs.holdTheIdentifyCard.onClick()"
          >{{
            $t("confirm")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
    <mt-popup v-model="popupEmployeeIdCardVisible">
      <div class="box">
        <div class="val">
          <img class="employeeIdCard" src="../../assets/employeeIdCard.png">
        </div>
        <div class="tips">
          {{ $t('employeeIdCardTips') }}
        </div>
        <div class="button">
          <mt-button
            type="primary"
            size="large"
            @click="popupEmployeeIdCardVisible = false; $refs.employeeIdCard.onClick()"
          >{{
            $t("confirm")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import EXIF from 'exif-js'
import updateFile from '@/components/updateFile.vue'
export default {
  name: 'UploadImage',
  props: {
    behavior: {
      type: String,
      default: 'P05'
    }
  },
  components: {
    updateFile,
  },
  data() {
    return {
      popupVisible: false,
      popupIdcardFrontVisible: false, // 身份证弹窗
      popupHoldTheIdentifyCardVisible: false, // 手持证件照弹窗
      popupEmployeeIdCardVisible: false, // 员工证弹窗
      defaultIndex: 0,
      form: {
        identifyCard: '', // 身份证
        holdTheIdentifyCard: '', // 手持身份证
        employeeIdCard: '', // 员工证
        voterCard: '', // 选民卡
        driverLicense: '', // 驾照
        passport: '', // 护照
        taxCard: '', // 税卡
      }
    }
  },
  computed: {
    ...mapState(['brand']),
    list() {
      return Object.values(this.form).filter(e => e);
    },
  },
  methods: {
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    validation() {
      if (this.brand.handheldUploadFlag == "Y" && this.form.holdTheIdentifyCard == null) {
        return this.$t('pleaseUploadHandHeldIdPhoto');
      }
    },
    onUploadImg(id, file) {
      let self = this
      EXIF.getData(file.origin, function() {
        let data = EXIF.getAllTags(this)
        if (data && data.MakerNote && data.MakerNote.length > 100) {
          data.MakerNote = data.MakerNote.slice(0, 100)
        }
        self.BEHAVIOR_ADD({
          id: id,
          newValue: JSON.stringify(data)
        })
      })
      this.$emit('input', this.list)
    },
    setDemoPic(val) {
      this.defaultIndex = val - 1
      this.popupVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.uploadImage {
  .iconchenggong {
    font-size: 24px;
    &.success {
      color: #1afa29;
    }
  }
  .error {
    font-size: 12px;
    color: red;
    font-size: 14px;
    text-align: center;
  }
  .border {
    margin: 35px 10px 10px;
    position: relative;
    padding: 20px 5px 20px 5px;
    border: 1px solid #e9e9e9;
    border-radius: 5px;
    .title {
      position: absolute;
      top: -10px;
      left: 30px;
      background: #fff;
      padding: 0 5px;
    }
    .content {
      display: flex;
      .item {
        padding: 0 5px;
        flex: 1;
        text-align: center;
        position: relative;
        img {
          width: 100%;
        }
        .txt {
          text-align: center;
          font-size: 12px;
        }
      }
    }
  }
  /deep/ .demopic {
    width: 250px;
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    img {
      width: 100%;
    }
    .mint-swipe {
      height: 250px;
    }
    .mint-swipe-indicator.is-active {
      background-color: black;
      opacity: 0.6;
    }
    .ok {
      text-align: center;
      color: black;
      line-height: 40px;
    }
  }
  /deep/ .mint-popup .box {
    min-width: 280px;
    padding: 15px;
    text-align: center;
    .val {
      .identifyCard {
        width: 250px;
      }
      .holdTheIdentifyCard {
        width: 215px;
      }
      .employeeIdCard {
        width: 120px;
      }
    }
    .button {
      margin-top: 15px;
    }
  }
}
</style>